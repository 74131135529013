/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ARBEITGEBER_FIXTURE_ID} from '../../arbeit-geber/shared/arbeit-geber.fixture';
import {ArbeitNehmer} from './arbeit-nehmer.model';
import moment from 'moment';

export const ARBEITNEHMER_FIXTURE_ID = 'f3218d92-b88e-41f9-98f5-bc299a3459cb';

export class ArbeitNehmerFixture extends ArbeitNehmer {

    constructor(id?: string, arbeitGeberId?: string) {
        super({
            id: id || ARBEITNEHMER_FIXTURE_ID,
            arbeitGeberId: arbeitGeberId || ARBEITGEBER_FIXTURE_ID
        });

        this.ahvNummerNeu = '756.2844.7686.50';

        this.name = 'Tester';
        this.vorname = 'Thomas';
        this.geburtsdatum = moment(Date.UTC(1990, 0, 1)).toDate();

        this.adresse.strasse = 'Some Road 11';
        this.adresse.plz = '3014';
        this.adresse.ort = 'Bern';
        this.adresse.land = 'Schweiz';
        this.adresse.postfach = 'Postfach';
    }
}
