/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ArbeitGeber} from './arbeit-geber.model';

export const ARBEITGEBER_FIXTURE_ID = 'ade7318b-9ddc-4257-9494-a76398034bb6';
export const FIXTURE_JAHR = 2018;

export class ArbeitGeberFixture extends ArbeitGeber {
    constructor(id?: string, firma?: string) {
        super({
            id: id || ARBEITGEBER_FIXTURE_ID,
            firma: firma || 'Testfirma',
            bearbeitungsJahr: FIXTURE_JAHR
        });

        this.telefon = '031 1111111';

        this.kontakt.name = 'Tester Thomas';
        this.kontakt.telefon = '031 2222222';

        this.adresse.plz = '3000';
        this.adresse.ort = 'Bern';
        this.adresse.land = 'Schweiz';
        this.adresse.postfach = 'Postfach';
        this.adresse.strasse = 'Einestrasse 12';
    }
}
